import React from "react"
import { Image, BoxProps, Flex } from "@chakra-ui/core"
import { Link } from "gatsby"
import GatsbyImage, { FluidObject } from "gatsby-image"
import styled from "@emotion/styled"
import Body from "../typography/Body"
import { bp } from "../../utils/MediaQueries"

type ArticleBlockItemProps = BoxProps & {
  image: FluidObject
  title: string
  subtext?: React.ReactNode
  tags?: any
  author?: any
  createdOn?: any
  to: string
}

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%
  max-height: 300px;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
`

export default function HowToBlogItem({
  image,
  title,
  subtext,
  to,
  tags,
  author,
  createdOn,
  ...props
}: ArticleBlockItemProps) {
  // Format the date string using toLocaleDateString()
  function convertDate(inputDate) {
    const date = new Date(inputDate)

    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  }

  return (
    <Link to={to} style={{ flexBasis: "100%", borderRadius: "5px" }}>
      <Flex
        flexDirection={bp("column", "row")}
        h={bp("100%", "100%")}
        maxH={bp("100%", "200px")}
        width="100%"
        textAlign="left"
        background="#eee"
        borderRadius="5px"
        {...props}
      >
        <Image
          src={image?.src}
          fallbackSrc="/images/emptyImg.png"
          alt="SixThreeZero"
          w={bp("100%", "330px")}
          maxW={bp("100%", "330px")}
          minW={bp("100%", "330px")}
          h={bp("auto", "auto")}
          maxH={bp("200px", "auto")}
          borderTopLeftRadius={bp("5px", "5px")}
          borderTopRightRadius={bp("0px", "0px")}
          borderBottomLeftRadius={bp("5px", "5px")}
          borderBottomRightRadius={bp("0px", "0px")}
          objectFit="cover"
        />
        <Flex flexDirection="column" p="15px 20px" justifyContent="center">
          <Body
            fontSize={bp("0.8rem", "1.2rem")}
            color="night"
            fontWeight="bold"
          >
            {title}
          </Body>
          {subtext && (
            <Body size="md" color="dawn" mt={bp("0.4825rem", "0.5rem")}>
              {subtext}
            </Body>
          )}
          <Body
            fontSize={bp("0.8rem", "0.8rem")}
            color="#999"
            fontWeight="bold"
            textTransform="uppercase"
            mt={bp("1rem", "1rem")}
          >
            {author?.name + " | " + convertDate(createdOn)}
          </Body>
        </Flex>
      </Flex>
    </Link>
  )
}
